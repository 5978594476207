import { BooleanInput } from '@angular/cdk/coercion';
import { Component, HostBinding, Input } from '@angular/core';
import { CoreIcon } from 'aimmo-core2/app/components/core-icon/core-icon.model';


@Component({
  selector: 'aim-core-icon',
  templateUrl: './core-icon.component.html',
  styleUrls: ['./core-icon.component.scss'],
  standalone: true,
  imports: []
})
export class CoreIconComponent {
  @Input() public icon: CoreIcon | null = null;

  @HostBinding('class.plus')
  public get isPlusIcon(): BooleanInput {
    return this.icon === CoreIcon.plus;
  }

  @HostBinding('class.message')
  public get isMessageIcon(): BooleanInput {
    return this.icon === CoreIcon.message;
  }

  @HostBinding('class.copy')
  public get isCopyIcon(): BooleanInput {
    return this.icon === CoreIcon.copy;
  }

  @HostBinding('class.trash')
  public get isTrashIcon(): BooleanInput {
    return this.icon === CoreIcon.trash;
  }

  @HostBinding('class.refresh')
  public get isRefreshIcon(): BooleanInput {
    return this.icon === CoreIcon.refresh;
  }

  @HostBinding('class.close')
  public get isCloseIcon(): BooleanInput {
    return this.icon === CoreIcon.close;
  }

  @HostBinding('class.arrow-up')
  public get isArrowUpIcon(): BooleanInput {
    return this.icon === CoreIcon.arrowUp;
  }

  @HostBinding('class.arrow-down')
  public get isArrowDownIcon(): BooleanInput {
    return this.icon === CoreIcon.arrowDown;
  }

  @HostBinding('class.arrow-right')
  public get isArrowRightIcon(): BooleanInput {
    return this.icon === CoreIcon.arrowRight;
  }

  @HostBinding('class.pencil')
  public get isPencilIcon(): BooleanInput {
    return this.icon === CoreIcon.pencil;
  }

  @HostBinding('class.user')
  public get isUserIcon(): BooleanInput {
    return this.icon === CoreIcon.user;
  }
}
